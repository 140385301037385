




























































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import FullCalendar from '@fullcalendar/vue';
import calendarStoreModule from '@/views/apps/calendar/calendarStoreModule'
import CalendarSidebar from '@/views/apps/calendar/calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from '@/views/apps/calendar/calendar-event-handler/CalendarEventHandler.vue'
import Calendar from '@/views/apps/calendar/Calendar.vue'
import store from '@/store'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import frLocale from "@fullcalendar/core/locales/fr";
import multiMonthPlugin from '@fullcalendar/multimonth'
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BFormCheckbox,
  BPagination,
  BRow,
  BTable,
  BBadge,
  BAlert,
  BFormGroup,
  BFormTag, 
  BLink
} from "bootstrap-vue";
import { VueSelect } from "vue-select";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { InfosSalariePlanning, DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { Entreprise } from "@/api/models/adherents/adherent";
import { CreateDemandeModificationModel, CreateDuplicationModel, CreateDuplicationSalarieModel, Creneau, CreneauCreateModel, DeleteCreneauxPeriodeModel, Simulation, ValidationPlanning } from "@/api/models/creneaux/creneau";
import AddCreneauModal from "./AddCreneauModal.vue"
import CreateDuplicationModal from "./CreateDuplicationModal.vue"
import CreateDuplicationSalariesModal from "./CreateDuplicationSalariesModal.vue"
import DeleteCreneauxPeriodeModal from "./DeleteCreneauxPeriodeModal.vue"
import CreateDemandeModificationModal from "./demandesModifications/CreateDemandeModificationModal.vue"
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import rrulePlugin from '@fullcalendar/rrule';
import { InfosPrestationPlanning, Prestation, PrestationSite } from "@/api/models/prestations/prestation";
import VueContext from 'vue-context';
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { RRule, RRuleSet, rrulestr } from 'rrule'
import { TypePreferenceVacation } from "@/api/models/enums/typePreferenceVacation";
import { EtatValidationPlanning } from "@/api/models/enums/etatValidationPlanningEnum";

@Component({
  components: {
    FullCalendar,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BFormSelect,
    BDropdownItem,
    BFormInput,
    BFormCheckbox,
    BPagination,
    BRow,
    BTable,
    BBadge,
    BAlert,
    BFormGroup,
    SearchableVueSelect,
    BFormTag,
    AddCreneauModal,
    VueContext,
    VueSelect,
    // ToastificationContent,
    BLink,
    CreateDuplicationModal,
    CreateDemandeModificationModal,
    CreateDuplicationSalariesModal,
    DeleteCreneauxPeriodeModal
    // FullCalendar,
    // CalendarEventHandler,
    // CalendarSidebar
  },
  directives: {
    Ripple
  }
})
export default class Plannings extends Vue {
  typePreferenceVacation = TypePreferenceVacation
  etatValidationPlanning = EtatValidationPlanning


  refreshIndex = 0
  isNavigating = false; 

  calendarLoading = true

  isEventHandlerSidebarActive = false
  isCalendarOverlaySidebarActive = false
  isDuplicationModaleActive = false

  startStr = ""
  endStr = ""

  isSearchAdherent = true

  listUtilisateurs = new PaginatedList<DetailsUtilisateur>();
  listEntreprises = new PaginatedList<Entreprise>();
  listPrestations = new PaginatedList<Prestation>();
  listSimulations: Simulation[] = []

  get getListSimulations() {
    if (!this.listSimulations.some(s => s.id == null)) {
      var defaultSimulation: Simulation = {
        id: null,
        nom: "Courant",
        description: ""
      }
      this.listSimulations.push(defaultSimulation)
    }

    return this.listSimulations 
  }

  simulationIdSelected: string | null = null

  prestationSelected: Prestation | null = null
  informationsSalarie: InfosSalariePlanning | null = null
  informationsPrestation: InfosPrestationPlanning | null = null

  itemSelected: Entreprise | DetailsUtilisateur | null = null

  listFiltersItems = new PaginatedList<any>();
  totalCountFiltersItems = 0
  filterEntreprisesIds: string[] = []
  filterSalariesIds: string[] = []

  badgeFilters: {id: string, libelle?: string, completeLabel?: string}[] = []

  calendar: any

  isCalendarMultiMonth: boolean = false

  previousDate?: any
  previousDateStr?: string
  previousViewType?: string


  events: {events?: {allDay?: boolean, start: Date, end: Date, moisAnnee: string, etat: EtatValidationPlanning}[]} = {}


  eventId = ""

  options : any = {
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, rrulePlugin, multiMonthPlugin],
    initialView: window.innerWidth < 1205 ? 'dayGridMonth' :'multiMonthThreeMonth',
    dayMaxEventRows: false,
    contentHeight:"auto",
    views: {
      multiMonthThreeMonth: {
        type: 'multiMonth',
        duration: { months: 3 },
        multiMonthMaxColumns : 3,
        buttonText: 'Trimestre',
      }
    },
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'multiMonthYear,multiMonthThreeMonth,dayGridMonth,timeGridWeek,listMonth',
    },
    footerToolbar: false,
    hiddenDays: [0, 6],
    locale: frLocale,
    allDaySlot: false,
    slotMinTime: "08:00:00",
    slotMaxTime: "18:30:00",
    events: async (params: any) => await this.searchEvents(params),
    droppable: false, 
    selectable: false,
    eventStartEditable: false,
    showNonCurrentDates: true,
    // loading: this.calendarLoading,
    // dragScroll: true,
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: false,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: false,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    // dayMaxEvents: 2,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,



    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar'
      },
    },
    viewDidMount: () => {
      var calendar = (this.$refs['refCalendar'] as any).getApi();
      calendar.today();
    },

    // select: this.selectEvent,

    eventClick: this.eventClick,
    eventMouseEnter: (info: any) => {
      const eventElement = info.el;
      if (eventElement) {
        const hoverDiv = document.createElement('span');
        hoverDiv.className = 'hover-end';
        hoverDiv.textContent = `${info.event._def.extendedProps.salariesCompleteLabels} ${info.event._def.extendedProps.siteLibelle != null ? ' - ' + info.event._def.extendedProps.siteLibelle : ''}`;
        
        hoverDiv.style.position = 'absolute';
        hoverDiv.style.top = '-100%'; // Au-dessus de l'événement
        hoverDiv.style.padding = '5px 10px';
        hoverDiv.style.backgroundColor = 'white';
        hoverDiv.style.borderRadius = '5px';
        hoverDiv.style.boxShadow = '0 2px 5px rgba(0,0,0,0.2)'; // Ajoute une ombre


        const eventElement = info.el;
        const calendarElement = info.view.calendar.el;
        
        // Calculer la position X de l'événement par rapport au calendrier
        const eventRect = eventElement.getBoundingClientRect();
        const calendarRect = calendarElement.getBoundingClientRect();
        
        const eventX = eventRect.left - calendarRect.left;
        const calendarMidpoint = calendarRect.width / 2;
        
        if (eventX < calendarMidpoint) {
          // sur la droite
          hoverDiv.style.left = '0%';
        } else {
          // sur la gauche
          hoverDiv.style.right = '0%';
        }
        
        eventElement.appendChild(hoverDiv);
      }
    },

    eventMouseLeave: (info: any) => {
      const eventElement = info.el;
      if (eventElement) {
        const hoverDiv = eventElement.querySelector('.hover-end');
        if (hoverDiv) {
          hoverDiv.remove();
        }
      }
    },


    datesSet: async function(this: Plannings, params:any) {
      await this.datesSetChange(params)

      if (this.isNavigating) {
        this.isNavigating = false;
        return;
      }

      const currentStart = new Date(); // Date actuelle de la vue
      const viewType = params.view.type;      
      
      var calendar = (this.$refs?.['refCalendar'] as any)?.getApi();
      if (!calendar)
        return;

      if (viewType === 'multiMonthThreeMonth' && viewType != this.previousViewType) {
        const startOfQuarter = this.getStartOfQuarter(currentStart);
        // console.log('Current start:', currentStart, 'Start of quarter:', startOfQuarter);
        if (currentStart.getTime() !== startOfQuarter.getTime()) {
          this.isNavigating = true;
          calendar.gotoDate(startOfQuarter);
        }
      }

      this.previousViewType = viewType;
    }.bind(this),

    dateClick: this.dateClick,
    moreLinkClick: (info: any) => {      
      setTimeout(() => {
        const popover = document.querySelector('.fc-popover') as HTMLElement;
        if (popover) {
          const popoverHeight = popover.offsetHeight;

          // Récupere les éléments de l'événement et du calendrier
          const eventElement = info.jsEvent.target;
          const calendarElement = info.view.calendar.el;

          // Calculer la position X de l'événement par rapport au calendrier
          const eventRect = eventElement.getBoundingClientRect();
          const calendarRect = calendarElement.getBoundingClientRect();
          
          const eventX = eventRect.left - calendarRect.left;
          const calendarMidpoint = calendarRect.width / 2;
          
          if (eventX < calendarMidpoint) {
            popover.style.left = `${eventRect.left}px`;
          } else {
            popover.style.left = `${eventRect.left - 150}px`;
          }
          // Place la popover juste au-dessus du jour cliqué
          popover.style.top = `${eventRect.top - popoverHeight}px`;
          popover.style.position = 'fixed';
        }
      }, 100);
    },

    eventDidMount: this.eventDidMount,

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    drop: this.dropEvent,

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent } : any) {
      // updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: store.state.appConfig.isRTL ? 'rtl' : 'ltr',
    rerenderDelay: 350,

    eventDisplay({ event: calendarEvent }: any) {
      return {
        'background': calendarEvent.backgroundColor
      }
    },
  }

  @Watch('window.innerWidth')
  resizeScreen(){
    if (window.innerWidth < 1205){
      this.options.headerToolbar = {
        start: 'sidebarToggle, prev,next, title',
        end: 'multiMonthYear,dayGridMonth,timeGridWeek,listMonth',
      }
      const calendarRef = this.$refs.refCalendar;
      if (calendarRef) {
        const calendarApi = (calendarRef as any).getApi();
        calendarApi.changeView('dayGridMonth');
      }
    }
    else {
      this.options.headerToolbar = {
        start: 'sidebarToggle, prev,next, title',
        end: 'multiMonthYear,multiMonthThreeMonth,dayGridMonth,timeGridWeek,listMonth',
      }
    }
  }

  mounted(){
    this.resizeScreen();
    window.addEventListener('resize', this.resizeScreen);
    window.addEventListener('scroll', this.disablePopover);
  }

  disablePopover() {
    const popover = document.querySelector('.fc-popover') as HTMLElement;
    if (popover) {
      popover.remove();
    }
  }

  async datesSetChange(params:any) {
    if (!this.previousDate) this.previousDate = params.start
    if (!this.previousDateStr) this.previousDateStr = params.startStr
    this.startStr = params.startStr
    this.endStr = params.endStr

    const viewType = params.view.type;

    this.isCalendarMultiMonth = viewType == 'multiMonthYear' || viewType == 'multiMonthThreeMonth'
    this.isOneMonthView = viewType == 'dayGridMonth' 

    //load infos salarie si changement d annee
    if (!this.previousDate) {
      if (this.isSearchAdherent) {
        await this.loadInfosPrestation(params.startStr, this.prestationSelected!.id)
      } else {
        await this.loadInfosSalarie(params.startStr)
      }
    } else if (this.previousDate.getYear() != params.start.getYear()) {
      if (this.isSearchAdherent) {
        await this.loadInfosPrestation(params.startStr, this.prestationSelected!.id)
      } else {
        await this.loadInfosSalarie(params.startStr)
      }
    }
    
    this.previousDate = params.start
    this.previousDateStr = params.startStr

    this.refreshIndex++
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  blankEvent = {
    title: '',
    start: '',
    end: '',
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      guests: [],
      location: '',
      description: '',
    }
  }
  eventToEdit: any = {}
  event: any = {}

  isOneMonthView = false

  get currentYear() {
    return this.previousDate.getFullYear()
  }

  clearEventData() {
    this.event = JSON.parse(JSON.stringify(this.blankEvent))
  }

  dateClick(arg: any) {
    if(!(this.$can('PLANNING_SUPERADMIN:admin') || this.$can('PLANNING_ADMIN:admin'))) return;

    this.event = JSON.parse(JSON.stringify(this.blankEvent))
    var date = new Date(arg.dateStr)
    this.event.dateCreneau = date

    var isDateClickedValide = false;

    if(!this.$can('PLANNING_SUPERADMIN:admin')) {
      var planningsValides = this.events.events!.filter(e => e.etat == this.etatValidationPlanning.VALIDE);
      var moisAnnee = ("0" + (date.getMonth() + 1)).slice(-2).toString() + date.getFullYear().toString()

      isDateClickedValide = planningsValides.some(p => moisAnnee == p.moisAnnee);
      if (isDateClickedValide == true) return;
    }

    if (isDateClickedValide == false) {
      if (arg.view.type != 'timeGridWeek') {
        this.event.start = '9:00:0'
        this.event.end = '12:30:0'
      } else {
        this.event.start = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
        var end = this.addMinutes(date, 210)
        this.event.end = end.getHours() + ":" + end.getMinutes() + ":" + end.getSeconds()
      }

      if (this.isSearchAdherent) {
        this.event.entrepriseId = this.itemSelected?.id
        this.event.entrepriseLibelle = this.itemSelected?.libelle
      }
      else
        this.event.salariesIds = [this.itemSelected?.id]
      
      this.isEventHandlerSidebarActive = true
    } else {
      this.isEventHandlerSidebarActive = false
    }
  }

  addMinutes(date: any, minutes: any) {
    return new Date(date.getTime() + minutes*60000);
  }

  eventDidMount(arg: any) {
    const eventId = arg.event.id

    if (arg.event.extendedProps.isForSystem == true) {
      return;
    }

    if (arg.el && 
      (this.$can('PLANNING_ADMIN:admin') ||
      this.$can('PLANNING_ADMIN:admin') || 
      this.$can('PLANNING_SUPERADMIN:admin') ||
      this.$can('PLANNING_SUPERADMIN:admin'))
    ) {
      if (!(this.$can('PLANNING_SUPERADMIN:admin') || this.$can('PLANNING_SUPERADMIN:admin') || this.$can('PLANNING_SUPERADMIN:admin')) && arg.event.extendedProps.isEditable == false) {
        return;
      }

      arg.el.addEventListener("contextmenu", (jsEvent: any)=>{

        if (arg.event.extendedProps.duplicationId) this.isMenuEvent = false;
        else this.isMenuEvent = true;

        (this.$refs.menu as any).open(jsEvent);
        jsEvent.preventDefault()

        this.event.id = eventId
        this.event.periodiciteId = arg.event.extendedProps.periodiciteId
        this.event.duplicationId = arg.event.extendedProps.duplicationId
        this.event.creneauId = arg.event.id

        this.refreshIndex++
        return;
      })
      this.refreshIndex++
    }
  }

  eventClick(arg: any) {
    if (arg.event.id == "null" || !arg.event.id) return 

    if (!(this.$can('PLANNING_SUPERADMIN:admin') ||
      this.$can('PLANNING_SUPERADMIN:admin'))
    ) {

      this.disablePopover();
      var date = new Date(arg.event.extendedProps.dateDebut)
      this.event.dateCreneau = date
      var planningsValides = this.events.events!.filter(e => e.etat == this.etatValidationPlanning.VALIDE);
      var moisAnnee = ("0" + (date.getMonth() + 1)).slice(-2).toString() + date.getFullYear().toString()

      var isDateClickedValide = planningsValides.some(p => moisAnnee == p.moisAnnee);

      this.event.selectEvent = isDateClickedValide

      this.event.id = arg.event.id
      this.event.periodiciteId = arg.event.extendedProps.periodiciteId
      this.event.duplicationId = arg.event.extendedProps.duplicationId
      this.event.creneauId = arg.event.id

      this.isEventHandlerSidebarActive = true
    }
  }

  isMenuEvent = false

  get menuData() {
    if (this.isMenuEvent)
      return this.menuEventData
    else 
      return this.menuDuplicateEventData
  }


  menuBasicData = [
    { icon: 'PlusIcon', text: 'Nouveau' , code: 'create'},
  ]

  menuEventData = [
    { icon: 'PlusIcon', text: 'Nouveau' , code: 'create'},
    { icon: 'EditIcon', text: 'Modifier', code: 'edit' },
    { icon: 'Trash2Icon', text: 'Supprimer', code: 'delete' },
    { icon: 'CopyIcon', text: 'Dupliquer', code: 'duplicate' },
  ]

  menuDuplicateEventData = [
    { icon: 'PlusIcon', text: 'Nouveau' , code: 'create'},
    { icon: 'EditIcon', text: 'Modifier', code: 'edit' },
    { icon: 'Trash2Icon', text: 'Supprimer', code: 'delete' },
    { icon: 'CopyIcon', text: 'Dupliquer', code: 'duplicate' },
    { icon: 'XCircleIcon', text: 'Dédupliquer', code: 'unduplicate' },
  ]

  optionClicked(data: {icon: string, text: string, code: string}) {
    switch (data.code) {
      case 'create':
        this.event = JSON.parse(JSON.stringify(this.blankEvent))
        var date = new Date()
        this.event.dateCreneau = date

        this.event.start = '9:00:0'
        this.event.end = '12:30:0'
        
        if (this.isSearchAdherent)
          this.event.entrepriseId = this.itemSelected?.id
        else
          this.event.salariesIds = [this.itemSelected?.id]

        this.isEventHandlerSidebarActive = true
        break;
      case 'delete':
        this.askDelete(this.event, this.event.periodiciteId != null)
        break;
      case 'edit':
        this.isEventHandlerSidebarActive = true
        break;
      case 'duplicate': 
        this.$bvModal.show('modal-create-duplication')
        break;
      case 'unduplicate':
        this.askUnduplicate()
    }
  }

  async created() {
    await this.searchEntreprises(null);
    await this.searchUsers(null);
    this.event = JSON.parse(JSON.stringify(this.blankEvent))
  }

  async askValidatePlanning() {
    this.$bvModal
      .msgBoxConfirm("Êtes-vous sûr ?", {
        title: "Confirmation de validation",
        size: "sm",
        okVariant: "success",
        okTitle: "Oui",
        cancelTitle: "Annuler",
        cancelVariant: "outline-primary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value != null) {
          await this.validatePlanning()
        }
      });
  }

  get planningStateOneMonthView() {
    var planningValidations = this.events.events!.filter(x => x.allDay == true);
    
    var calendar = (this.$refs['refCalendar'] as any).getApi();

    const moisCalendar = ("0" + (calendar.currentData.currentDate.getMonth() + 1)).slice(-2)
    const anneeCalendar = calendar.currentData.currentDate.getFullYear()

    var pv = planningValidations.find(pv => ("0" + (new Date(pv.start).getMonth() + 1)).slice(-2) == moisCalendar && new Date(pv.start).getFullYear() == anneeCalendar);

    this.refreshIndex++

    if (pv != undefined) {
      return pv.etat
    } else {
      return this.etatValidationPlanning.NON_VALIDE
    }
  }
  getStartOfQuarter(date: any) {
    const month = date.getMonth();
    const quarterStartMonth = Math.floor(month / 3) * 3; // 0 = Janvier, 3 = Avril, 6 = Juillet, 9 = Octobre
    return new Date(date.getFullYear(), quarterStartMonth, 1);
  }

  getStartOfWeek(date: any) {
    const day = date.getDay();
    const diff = (day === 0 ? -6 : 1) - day; // Ajustement pour que lundi soit le premier jour de la semaine
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff);
  }
  async validatePlanning() {
    var calendar = (this.$refs['refCalendar'] as any).getApi();

    const mois = ("0" + (calendar.currentData.currentDate.getMonth() + 1)).slice(-2)
    const anneeMois = mois + calendar.currentData.currentDate.getFullYear()

    await this.$http.myressif.utilisateurs
      .updateValidationPlanning(this.itemSelected?.id, anneeMois)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Validation du planning",
            text: "Validation du planning effectuée",
          });
          if (this.isSearchAdherent)
            await this.loadInfosPrestation(this.previousDateStr!, this.prestationSelected!.id)
          else 
            await this.loadInfosSalarie(this.previousDateStr!)
          
          this.refetchEvents()
          this.refreshIndex++
          // this.refreshPlanningStateOneMonthView()
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.refetchEvents();
      });
  }

  askCreateDemandeModification() {
    this.$bvModal
      .msgBoxConfirm("Êtes-vous sûr ?", {
        title: "Confirmation de demande de modification",
        size: "sm",
        okVariant: "warning",
        okTitle: "Oui",
        cancelTitle: "Annuler",
        cancelVariant: "outline-primary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value != null) {
          this.showDemandeModification()
        }
      });
  }

  showDemandeModification() {
    this.$bvModal.show('modal-create-demande-modification')
  }

  async createDemandeModification(demandeModification: CreateDemandeModificationModel) {
    var calendar = (this.$refs['refCalendar'] as any).getApi();

    const mois = ("0" + (calendar.currentData.currentDate.getMonth() + 1)).slice(-2)
    const anneeMois = mois + calendar.currentData.currentDate.getFullYear()

    demandeModification.moisAnnee = anneeMois
    demandeModification.utilisateurId = this.itemSelected!.id

    await this.$http.myressif.utilisateurs
      .createDemandeModification(this.itemSelected!.id, demandeModification)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Demande de modification du planning",
            text: "Demande envoyée avec succès",
          });


          if (this.isSearchAdherent)
            await this.loadInfosPrestation(this.previousDateStr!, this.prestationSelected!.id)
          else 
            await this.loadInfosSalarie(this.previousDateStr!)

          this.refetchEvents()

          // this.refreshPlanningStateOneMonthView
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$bvModal.hide('modal-create-demande-modification')
        this.refetchEvents();
      });
  }

  async askValidateSimulation() {
    this.$bvModal
      .msgBoxConfirm("Êtes-vous sûr ?", {
        title: "Confirmation de validation",
        size: "sm",
        okVariant: "success",
        okTitle: "Oui",
        cancelTitle: "Annuler",
        cancelVariant: "outline-primary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value != null) {
          await this.validateSimulation()
        }
      });
  }

  async validateSimulation() {
    await this.$http.myressif.simulations
      .validate(
        this.simulationIdSelected
      )
      .then(
        (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Validation de la simulation",
            text: "Simulation validée avec succès",
          });
          this.simulationIdSelected = null;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(async () => {
        this.refetchEvents();
        await this.loadListSimulations(null)
      });
  }

  showDuplicationSalarieModal() {
    this.$bvModal.show('modal-create-duplication-salarie')
  }

  showDeleteCreneauxPeriodeModal() {
    this.$bvModal.show('modal-delete-creneaux-periode')
  }

  askUnduplicate() {
    this.$bvModal
      .msgBoxConfirm("Êtes-vous sûr ?", {
        title: "Confirmation de déduplication",
        size: "sm",
        okVariant: "danger",
        okTitle: "Oui",
        cancelTitle: "Annuler",
        cancelVariant: "outline-primary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value != null) {
          await this.deleteDuplication(this.event.creneauId, this.event.duplicationId)
        }
      });
  }

  // async dateRangeChanged(params: any) {
  //   if (this.isNavigating) {
  //     this.isNavigating = false;
  //     this.refreshIndex++;
  //     return;
  //   }

  //   if (!this.previousDate) this.previousDate = params.start
  //   if (!this.previousDateStr) this.previousDateStr = params.startStr
  //   this.startStr = params.startStr
  //   this.endStr = params.endStr

  //   const currentStart = new Date(params.start); // Date actuelle de la vue
  //   const viewType = params.view.type;

  //   this.isCalendarMultiMonth = viewType == 'multiMonthYear' || viewType == 'multiMonthThreeMonth'
  //   this.isOneMonthView = viewType == 'dayGridMonth'    
    

    
  //   var calendar = (this.$refs?.['refCalendar'] as any)?.getApi();
  //   if (!calendar)
  //     return;
  //   if (viewType === 'multiMonthThreeMonth') {
  //     const startOfQuarter = this.getStartOfQuarter(currentStart);
  //     if (currentStart.getTime() !== startOfQuarter.getTime()) {
  //       this.isNavigating = true;
  //       calendar.gotoDate(startOfQuarter);
  //     }
  //   } else if (viewType === 'dayGridMonth') {
  //     const startOfMonth = new Date(currentStart.getFullYear(), currentStart.getMonth(), 1);
  //     if (currentStart.getTime() !== startOfMonth.getTime()) {
  //       this.isNavigating = true;
  //       calendar.gotoDate(startOfMonth);
  //     }
  //   } else if (viewType === 'timeGridWeek') {
  //     const startOfWeek = this.getStartOfWeek(currentStart);
  //     if (currentStart.getTime() !== startOfWeek.getTime()) {
  //       this.isNavigating = true;
  //       calendar.gotoDate(startOfWeek);
  //     }
  //   } else if (viewType === 'timeGridDay') {
      
  //   } else if (viewType === 'listMonth') {
      
  //   }

  //   //load infos salarie si changement d annee
  //   if (!this.previousDate) {
  //     if (this.isSearchAdherent) {
  //       await this.loadInfosPrestation(params.startStr, this.prestationSelected!.id)
  //     } else {
  //       await this.loadInfosSalarie(params.startStr)
  //     }
  //   } else if (this.previousDate.getYear() != params.start.getYear()) {
  //     if (this.isSearchAdherent) {
  //       await this.loadInfosPrestation(params.startStr, this.prestationSelected!.id)
  //     } else {
  //       await this.loadInfosSalarie(params.startStr)
  //     }
  //   }
    
  //   this.previousDate = params.start
  //   this.previousDateStr = params.startStr

  //   this.refreshIndex++
  // }



  setupDraggable() {
    var that = this
    new Draggable(document.getElementById("planned-tasks")!, {
      itemSelector: ".fc-event",
      eventData: function(eventEl) {
        let event = {
          salariesCompleteLabels: that.isSearchAdherent ? eventEl.innerHTML : that.itemSelected!.completeLabel,
          entrepriseLibelle: that.isSearchAdherent ? that.itemSelected!.libelle : eventEl.innerHTML,
          duration: "03:30",
          create: false,
          salaries: [{completeLabel: eventEl.innerHTML, id: eventEl.id}]
        };
        that.event = event;
        that.$emit("update:is-event-handler-sidebar-active", true);
        return event;
      }
    });
  }

  selectEvent(dataEvent: any) {
    this.event.selectEvent = true
    var start = dataEvent.start
    this.event.start = start.getHours() + ":" + start.getMinutes() + ":" + start.getSeconds()

    var end = dataEvent.end
    this.event.end = end.getHours() + ":" + end.getMinutes() + ":" + end.getSeconds()

    this.event.dateCreneau = new Date(start)

    if (this.isSearchAdherent)
      this.event.entrepriseId = this.itemSelected?.id
    else
      this.event.salariesIds = [this.itemSelected?.id]

    this.isEventHandlerSidebarActive = true
  }

  dropEvent(dataEvent: any) {
    var start = dataEvent.date
    var date = new Date(start)

    var planningsValides = this.events.events!.filter(e => e.etat == this.etatValidationPlanning.VALIDE);
    var moisAnnee = ("0" + (date.getMonth() + 1)).slice(-2).toString() + date.getFullYear().toString()

    var isDateClickedValide = planningsValides.some(p => moisAnnee == p.moisAnnee);

    this.event.selectEvent = isDateClickedValide
    this.event.start = '9:00:0'    
    this.event.end = '12:30:0'

    this.event.dateCreneau = new Date(start)

    if (this.isSearchAdherent) {
      this.event.entrepriseId = this.itemSelected?.id
      this.event.salariesIds = [dataEvent.draggedEl.id]
    } else {
      this.event.salariesIds = [this.itemSelected?.id]
      this.event.entrepriseId = dataEvent.draggedEl.id
    }

    this.isEventHandlerSidebarActive = true
  }

  newCreneau() {
    this.event = JSON.parse(JSON.stringify(this.blankEvent))
    var date = new Date()
    this.event.dateCreneau = date

    this.event.start = '9:00:0'
    this.event.end = '12:30:0'
    
    if (this.isSearchAdherent)
      this.event.entrepriseId = this.itemSelected?.id
    else {
      this.event.salariesIds = [this.itemSelected?.id]
      this.event.salaries = [{
        id: this.itemSelected!.id,
        completeLabel: this.itemSelected!.completeLabel
      }]
    }

    this.isEventHandlerSidebarActive = true
  }

  isSearchChanged() {
    this.listFiltersItems = new PaginatedList<any>()
    this.badgeFilters = []
    this.itemSelected = null
  }

  async searchEvents(params: any) {
    this.calendarLoading = true
    await this.$http.myressif.creneaux
      .paginatedList(
        params.startStr,
        params.endStr,
        this.isSearchAdherent ? this.itemSelected!.id : "",
        this.isSearchAdherent ? "" : this.itemSelected!.id,
        this.isSearchAdherent ? this.filterSalariesIds : [],
        this.isSearchAdherent ? [] : this.filterEntreprisesIds,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        this.simulationIdSelected
      )
      .then((response: PaginatedList<any>) => {
        response.items.forEach((e: any) => {
          e.extendedProps = {periodiciteId: e.periodiciteId}
        })

        this.events.events = response.items
      }
    );

    this.calendarLoading = false
    return this.events.events
  }

  get getSearchPlaceholder() {
    if (this.isSearchAdherent)
      return "Rerchercher une entreprise..."
    else
      return "Rechercher un utilisateur..."
  }

  get getSearchLabel() {
    if (this.isSearchAdherent)
      return "libelle"
    else
      return "completeLabel"
  }

  goToHome() {
    this.$router.push({ name: "home" });
  }

  async search(params: any) {
    if (this.isSearchAdherent)
      await this.searchEntreprises(params)
    else
      await this.searchUsers(params)
  }

  @Watch("simulationIdSelected")
  refetchEvents() {
    var calendar = (this.$refs['refCalendar'] as any).getApi();
    const currentStart = new Date();
    const startOfQuarter = this.getStartOfQuarter(currentStart);
    calendar.gotoDate(startOfQuarter);
    calendar.refetchEvents();
  }

  async selectItem() {
    if (this.isSearchAdherent){
      await this.searchPrestationsByEntrepriseId(null, this.itemSelected!.id)
      if (this.listPrestations.items.length > 0) {
        this.prestationSelected = this.listPrestations.items[0]
        await this.loadInfosPrestation(this.previousDateStr!, this.listPrestations.items[0].id)
      } else {
        this.prestationSelected = null
      }
      
    } else {
      await this.loadInfosSalarie(this.previousDateStr!)
    }

    await this.searchBadgeFilters(null)
    this.listFiltersItems = new PaginatedList<any>();
    await this.searchFilters(null)

    if (this.$can('PLANNING_ADMIN:admin') || this.$can('PLANNING_SUPERADMIN:admin')) await this.loadListSimulations(null)
    
    this.refetchEvents()
  }

  async searchUsers(params: any) {
    await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listUtilisateurs.items = this.listUtilisateurs!.items.concat(
            response.items.filter(
              (ri) => !this.listUtilisateurs!.items.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: DetailsUtilisateur) => <DetailsUtilisateur>{
          ...x,
          completeLabel: `${x.nom} ${x.prenom}`
        });
        this.listUtilisateurs.hasNextPage = response.hasNextPage
        this.listUtilisateurs.hasPreviousPage = response.hasPreviousPage
        this.listUtilisateurs.pageIndex = response.pageIndex
        this.listUtilisateurs.totalCount = response.totalCount
        this.listUtilisateurs.totalPages = response.totalPages
      });
  }

  async loadUtilisateur(userId: string) {
    await this.$http.myressif.utilisateurs
      .getById(userId)
      .then(async (res: DetailsUtilisateur) => {
        this.itemSelected = res
        this.itemSelected!.completeLabel = `${res.nom} ${res.prenom}`

        await this.searchBadgeFilters(null)
      });
  }

  async loadListSimulations(params: any) {
    if (!params || params?.reset) {
      this.listSimulations = []
    }
    await this.$http.myressif.simulations
      .paginatedList(params?.search, 1, 50)
      .then((response: PaginatedList<Simulation>) => {
        this.listSimulations = response.items;
      });
  }

  async searchFilters(params: any) {
    if (!this.itemSelected)
      return

    if (!this.isSearchAdherent) {
      await this.$http.myressif.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? "",
        params?.pertinentOnly,
        params?.sortStatut,
        this.itemSelected.id
      )
      .then((response: PaginatedList<Entreprise>) => {
        this.listFiltersItems.items = this.listFiltersItems!.items.concat(
            response.items.filter(
              (ri) => !this.listFiltersItems!.items.some((lsi) => lsi.id == ri.id)
            )
          );
        
        this.totalCountFiltersItems = response.totalCount
      });
    } else {
      await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        null,
        null,
        null,
        null,
        this.itemSelected.id
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.listFiltersItems.items = this.listFiltersItems!.items.concat(
            response.items.filter(
              (ri) => !this.listFiltersItems!.items.some((lsi) => lsi.id == ri.id)
            )
          ).map((x: DetailsUtilisateur) => <DetailsUtilisateur>{
          ...x,
          libelle: `${x.nom} ${x.prenom}`
        });

        this.totalCountFiltersItems = response.totalCount
      });
    }
  }

  async searchBadgeFilters(params: any) {
    if (!this.isSearchAdherent && this.itemSelected) {
      await this.$http.myressif.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? "",
        params?.pertinentOnly,
        params?.sortStatut,
        this.itemSelected.id
      )
      .then((response: PaginatedList<Entreprise>) => {
        this.badgeFilters = response.items.map((x: Entreprise) => <Entreprise>{
          libelle: x.libelle,
          id: x.id
        })
        this.setupDraggable();
      });
    } else if (this.isSearchAdherent && this.itemSelected) {
      await this.$http.myressif.utilisateurs
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        null,
        null,
        null,
        null,
        this.itemSelected.id
      )
      .then((response: PaginatedList<DetailsUtilisateur>) => {
        this.badgeFilters = response.items.map((x: DetailsUtilisateur) => <{id: string, libelle: string}>{
          id: x.id,
          libelle: x.nom + " " + x.prenom,
        })
        this.setupDraggable();
      });
    }
  }

  async searchEntreprises(params: any) {
    await this.$http.myressif.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? "",
        params?.pertinentOnly,
        params?.sortStatut
      )
      .then((response: PaginatedList<Entreprise>) => {
        this.listEntreprises.items = this.listEntreprises!.items.concat(
            response.items.filter(
              (ri) => !this.listEntreprises!.items.some((lsi) => lsi.id == ri.id)
            )
          );
        this.listEntreprises.hasNextPage = response.hasNextPage
        this.listEntreprises.hasPreviousPage = response.hasPreviousPage
        this.listEntreprises.pageIndex = response.pageIndex
        this.listEntreprises.totalCount = response.totalCount
        this.listEntreprises.totalPages = response.totalPages
      });
  }

  async loadInfosSalarie(startDate: string) {
    await this.$http.myressif.utilisateurs
      .getInfosSalariePlanning(this.itemSelected?.id, startDate)
      .then(async (res: InfosSalariePlanning) => {
        this.informationsSalarie = res;
        this.refreshIndex++;
      });
  }

  async loadInfosPrestation(startDate: string, prestationId: string) {
    await this.$http.myressif.entreprises
      .getInfosPrestationById(this.itemSelected?.id, prestationId, startDate)
      .then(async (res: InfosPrestationPlanning) => {
        this.informationsPrestation = res;
        this.refreshIndex++;
      });
  }

  async loadAdherent(entrepriseId: string) {
    await this.$http.myressif.entreprises
      .getById(entrepriseId)
      .then(async (res: Entreprise) => {
        this.itemSelected = res;
        this.badgeFilters = []

        await this.searchBadgeFilters(null)
      });
  }

  async searchPrestationsByEntrepriseId(params: any, entrepriseId: string) {
    if (!params || params?.reset) {
      this.listPrestations = new PaginatedList<Prestation>();
    }
    await this.$http.myressif.entreprises
      .getListPrestationsByEntrepriseId(
        entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Prestation>) => {
        this.listPrestations.items = this.listPrestations!.items.concat(
            response.items.filter(
              (ri) => !this.listPrestations!.items.some((lsi) => lsi.id == ri.id)
            )
          );
        this.listPrestations.totalCount = response.totalCount
      });
  }

  async loadPrestationsSitesByPrestationId(prestationId: string, annee: number) {
    await this.$http.myressif.entreprises
      .getListPrestationsSitesByPrestationId(this.itemSelected!.id, prestationId, annee)
      .then(async (res: PrestationSite[]) => {
        this.prestationSelected!.prestationsSites = res;

        this.refreshIndex++
      })
  }

  async loadItem() {
    if (this.isSearchAdherent)
      await this.loadAdherent(this.itemSelected!.id)
    else  
      await this.loadUtilisateur(this.itemSelected!.id)
  }

  async askDelete(creneau: any, isPeriodique: boolean) {
    const id = creneau.id

    if (isPeriodique) {
      this.$bvModal
        .msgBoxConfirm("Supprimer uniquement ce créneau ou toutes les occurrences ?", {
          title: "Confirmation de suppression",
          size: "sm",
          okVariant: "primary",
          okTitle: "Uniquement ce créneau",
          cancelTitle: "Toutes les occurrences",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value === true) await this.removeEvent(id)
          if (value === false) await this.removeCreneauxByPeriodiciteId(creneau.id, creneau.periodiciteId)
          // await this.removeEvent(id)
          return;
        });
    } else {
      this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer cet événement ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.removeEvent(id);
        } else {
          return;
        }
      });
    } 
  }

  async removeEvent(id: string) {
    await this.$http.myressif.creneaux
      .delete(id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un événement",
            text: "Suppression effectuée avec succès",
          });

          if (this.isSearchAdherent)
            await this.loadInfosPrestation(this.previousDateStr!, this.prestationSelected!.id)
          else 
            await this.loadInfosSalarie(this.previousDateStr!)
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.refetchEvents();
      });
  }

  async removeCreneauxByPeriodiciteId(id: string, periodiciteId: string) {
    await this.$http.myressif.creneaux
      .deleteCreneauxByPeriodicite(id, periodiciteId)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression de créneaux",
            text: "Suppression effectuée avec succès",
          });

          if (this.isSearchAdherent)
            await this.loadInfosPrestation(this.previousDateStr!, this.prestationSelected!.id)
          else 
            await this.loadInfosSalarie(this.previousDateStr!)
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.refetchEvents();
      });
  }

  async askUpdate(eventData: any) {
    if (eventData.periodicite && eventData.periodicite.id) {
      this.$bvModal
        .msgBoxConfirm("Modifier uniquement ce créneau ou toutes les occurrences ?", {
          title: "Confirmation de modification",
          size: "sm",
          okVariant: "primary",
          okTitle: "Uniquement ce créneau",
          cancelTitle: "Toutes les occurrences",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value != null) {
            eventData.updateAllOccurrences = !value
            await this.updateEvent(eventData)
          }
        });
    } else {
      await this.updateEvent(eventData)
    }    
  }

  async updateEvent(eventData: any) {
    var date = new Date(eventData.dateCreneau)

    eventData.dateDebut = date.toLocaleDateString('fr-FR'),
    eventData.heureDebut = eventData.start,
    eventData.dateFin = date.toLocaleDateString('fr-FR'),
    eventData.heureFin = eventData.end,

    await this.$http.myressif.creneaux
      .update(eventData.id, eventData)
      .then(
        async (response: any) => {
          this.refetchEvents();

          if (this.isSearchAdherent)
            await this.loadInfosPrestation(this.previousDateStr!, this.prestationSelected!.id)
          else 
            await this.loadInfosSalarie(this.previousDateStr!)

          if (eventData.periodicite) {
            successAlert.fire({
              timer: 3500,
              title: "Modification de créneaux",
              text: "Créneaux modifiés avec succès",
            });
          } else {
            successAlert.fire({
              timer: 3500,
              title: "Modification d'un créneau",
              text: "Créneau modifié avec succès",
            });
          }

          

          this.clearEventData()
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("update:is-event-handler-sidebar-active", false);
      });
  }

  async addEvent(eventData: any) {
    var dateCreneau = new Date(eventData.dateCreneau)

    var newCreneau: CreneauCreateModel = {
      entrepriseId: eventData.entrepriseId,
      dateDebut: dateCreneau.toLocaleDateString('fr-FR'),
      heureDebut: eventData.start,
      dateFin: dateCreneau.toLocaleDateString('fr-FR'),
      heureFin: eventData.end,
      typeCreneauId: eventData.typeCreneauId,
      commentaire: eventData.commentaire,
      commentairePlanning: eventData.commentairePlanning,
      salariesIds: eventData.salariesIds,
      salariesAlerterIds: eventData.salariesAlerterIds,
      prestationId: eventData.prestationId,
      siteId: eventData.siteId,
      periodicite: eventData.isPeriodique ? eventData.periodicite : null,
      isPeriodique: eventData.isPeriodique,
      simulationId: this.simulationIdSelected
    }    

    await this.$http.myressif.creneaux
      .post(newCreneau)
      .then(
        async (response: any) => {
          await this.searchEvents({startStr: this.startStr, endStr: this.endStr})

          if (this.isSearchAdherent && this.prestationSelected)
            await this.loadInfosPrestation(this.previousDateStr!, this.prestationSelected!.id)
          else 
            await this.loadInfosSalarie(this.previousDateStr!)

          if (newCreneau.periodicite) {
            successAlert.fire({
              timer: 3500,
              title: "Création de créneaux",
              text: "Créneaux créés avec succès",
            });
          } else {
            successAlert.fire({
              timer: 3500,
              title: "Création d'un créneau",
              text: "Créneau créé avec succès",
            });
          }    

          this.refetchEvents();
          this.clearEventData()
          this.isEventHandlerSidebarActive = false
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("update:is-event-handler-sidebar-active", false);
      });
  }

  async createDuplication(duplication: CreateDuplicationModel) {
    duplication.creneauId = this.event.creneauId

    await this.$http.myressif.creneaux
      .postDuplication(duplication)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Duplication d'un créneau",
            text: "Duplication effectuée avec succès",
          });

          if (this.isSearchAdherent)
            await this.loadInfosPrestation(this.previousDateStr!, this.prestationSelected!.id)
          else 
            await this.loadInfosSalarie(this.previousDateStr!)
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$bvModal.hide('modal-create-duplication')
        this.refetchEvents();
      });
  }

  async deleteDuplication(creneauId: string, duplicationId: string) {
    await this.$http.myressif.creneaux
      .deleteDuplication(creneauId, duplicationId)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Déduplication de créneau(x)",
            text: "Déduplication effectuée avec succès",
          });

          if (this.isSearchAdherent)
            await this.loadInfosPrestation(this.previousDateStr!, this.prestationSelected!.id)
          else 
            await this.loadInfosSalarie(this.previousDateStr!)
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.refetchEvents();
      });
  }

  async createDuplicationSalarie(duplication: CreateDuplicationSalarieModel) {
    duplication.fromUserId = this.itemSelected!.id

    await this.$http.myressif.planningsUtils
      .createDuplicationSalarie(duplication)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Duplication d'un créneau",
            text: "Duplication effectuée avec succès",
          });

          if (this.isSearchAdherent)
            await this.loadInfosPrestation(this.previousDateStr!, this.prestationSelected!.id)
          else 
            await this.loadInfosSalarie(this.previousDateStr!)
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$bvModal.hide('modal-create-duplication-salarie')
        this.refetchEvents();
      });
  }

  async deleteCreneauxPeriode(model: DeleteCreneauxPeriodeModel) {
    await this.$http.myressif.planningsUtils
      .deleteCreneauxPeriode(model)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'une période",
            text: "Suppression effectuée avec succès",
          });

          if (this.isSearchAdherent)
            await this.loadInfosPrestation(this.previousDateStr!, this.prestationSelected!.id)
          else 
            await this.loadInfosSalarie(this.previousDateStr!)
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$bvModal.hide('modal-delete-creneaux-periode')
        this.refetchEvents();
      });
  }

}
